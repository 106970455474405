<template>
	<div v-if="tempShowHide">
		<div class="filter-header">
			<h6 class="fw400">Filter</h6>
			<button class="btn btn-link text-primary reset-btn" @click="resetAllFilters">Filter zurücksetzen</button>
		</div>

		<dependency-filter-structure v-for="(item, index) in structuredFilters" :key="item.field_type_id" :parent-field="item" :selectedFilters="selectedFilters" @on-select="filterChanged"/>

		<WBSelectField v-if="sublocalityFilter!==null" :key="sublocalityFilter.field_type_id" class="mb15" :name="sublocalityFilter.label" :field-type-id="sublocalityFilter.field_type_id"  :values="sublocalityFilter.options" :selectedFilters="selectedFilters" :isMultiple="true" @on-select="filterChanged" />

		<h6 class="fw400 time-picker-header">Zeitraum</h6>

		<custom-date-picker :initialValue="dateTimeSelection.selectedStartDate" label="Start-Datum wählen" @changedDate="startDateChanged"/>

		<custom-date-picker :initialValue="dateTimeSelection.selectedEndDate" label="End-Datum wählen (optional)" @changedDate="endDateChanged"/>

		<time-picker customClass="mt30 pb10" :initialValue="dateTimeSelection.selectedStartTime" label="Start-Uhrzeit ab" @changedTime="startTimeChanged"/>

		<time-picker :initialValue="dateTimeSelection.selectedEndTime" label="End-Uhrzeit bis (optional)" @changedTime="endTimeChanged"/>

	</div>
</template>
<script>
	import { getSublocalities } from '@/api/system';
	import { addZero, getReadableTime, getFilterDate, compareAndSwitchDates, getWeekday, getMonth } from '@/utils/appointment-helpers';
	import { showLoader, hideLoader, getFilterFields, createSelectOptions, getAllowedValuesFromFieldType, argsFilterMapToJSMap, isset, changeRouteQuery } from '@/utils/helpers';
	import { screenSizeMixin } from '@/mixins/screenSizeMixin';
	import VueScrollTo from 'vue-scrollto';

	export default {
		name: "AngeboteFilter",
		mixins: [screenSizeMixin],
		components: {
			WBSelectField: () => import('@/components/controls/WBSelectField.vue'),
			DependencyFilterStructure: () => import('@/components/controls/DependencyFilterStructure.vue'),
			TimePicker: () => import('@/components/controls/TimePicker'),
			CustomDatePicker: () => import('@/components/controls/CustomDatePicker'),
			ToggleSwitch: () =>import('@/components/controls/ToggleSwitch')
		},
		data() {
			return {
				tempShowHide: true,
				selectedCategory: null,
				expandFilter: false,
				dateTimeSelection: {
					selectedEndDate: null,
					selectedStartDate: null,
					selectedStartTime: null,
					selectedEndTime: null,
				}
			}
		},
		props: {
			contentType: {
				type: Object,
				required: true,
			},
			selectedFilters: {
				type: Map,
				required: true,
			},
			structuredFilters: {
				type: Array,
				required: true
			},
			sublocalityFilter: {
				type: Object,
				required: true
			}
		},
		created(){
			this.$scrollTo();
		},
		computed: {
			readableStartDate() {
				if (this.dateTimeSelection.selectedStartDate != null) {
					let d = this.dateTimeSelection.selectedStartDate === null ? new Date() : new Date(this.dateTimeSelection.selectedStartDate);
					let day = getWeekday(d.getDay()).substring(0, 3);
					let month = getMonth(d.getMonth()+1).substring(0, 3);
					return day + ', ' + d.getDate() + '.' + ' ' + month + ' ' + d.getFullYear();
				}
				return '';
			},
			kinderbetreuungFieldType(){
				let lang = this.$store.getters.getLanguage;
				switch(lang){
				case 'de':
					return 81;
					break;
				case 'en':
					return 82;
					break;
				case 'ar':
					return 186;
					break;
				case 'tr':
					return 83;
					break;
				case 'uk':
					return 322;
					break;
				}
			},
			barrierefreiheitFieldType(){
				let lang = this.$store.getters.getLanguage;
				switch(lang){
				case 'de':
					return 84;
					break;
				case 'en':
					return 85;
					break;
				case 'ar':
					return 187;
					break;
				case 'tr':
					return 86;
					break;
				case 'uk':
					return 323;
					break;
				}
			},
		},
		methods: {
			showLoader, 
			hideLoader,
			getSublocalities, 
			createSelectOptions,
			getAllowedValuesFromFieldType,
			setDateQuery(){
				this.$emit('dateTimeChanged', this.dateTimeSelection);
			},
			timeFilterChanged(timeDateObject){
				this.dateTimeSelection = timeDateObject;
				this.setDateQuery();
			},
			startDateChanged(dateValue){
				this.dateTimeSelection.selectedStartDate=dateValue;
				var resObject = compareAndSwitchDates(this.dateTimeSelection.selectedStartDate,this.dateTimeSelection.selectedEndDate);
				this.dateTimeSelection.selectedStartDate=resObject.start;
				this.dateTimeSelection.selectedEndDate=resObject.end;
				this.setDateQuery();
			},
			endDateChanged(dateValue){
				this.dateTimeSelection.selectedEndDate=dateValue;
				var resObject = compareAndSwitchDates(this.dateTimeSelection.selectedStartDate,this.dateTimeSelection.selectedEndDate);
				this.dateTimeSelection.selectedStartDate=resObject.start;
				this.dateTimeSelection.selectedEndDate=resObject.end;
				this.setDateQuery();
			},
			startTimeChanged(timeValue){
				this.dateTimeSelection.selectedStartTime=timeValue;
				var resObject = compareAndSwitchDates(this.dateTimeSelection.selectedStartTime,this.dateTimeSelection.selectedEndTime);
				this.dateTimeSelection.selectedStartTime=resObject.start;
				this.dateTimeSelection.selectedEndTime=resObject.end;
				this.setDateQuery();
			},
			endTimeChanged(timeValue){
				this.dateTimeSelection.selectedEndTime=timeValue;
				var resObject = compareAndSwitchDates(this.dateTimeSelection.selectedStartTime,this.dateTimeSelection.selectedEndTime);
				this.dateTimeSelection.selectedStartTime=resObject.start;
				this.dateTimeSelection.selectedEndTime=resObject.end;
				this.setDateQuery();
			},
			filterChanged(fieldTypeId,values){
				this.tempShowHide = false;
				this.expandFilter = false;
				this.selectedFilters.set(fieldTypeId, values);
				this.expandFilter = true;
				this.$emit('filterChanged', this.selectedFilters);
				this.$nextTick(() => {
					this.tempShowHide = true;
				});
			},
			sliderChanged(fieldTypeId,value){
				this.expandFilter = false;
				if(value){
					this.selectedFilters.set(fieldTypeId, ["Kostenlos"]);
				}
				else {
					this.selectedFilters.delete(fieldTypeId);
				}
				this.$emit('filterChanged', this.selectedFilters);
				this.expandFilter = true;
			},
			barrierefreiheitChanged(fieldTypeId,value){
				this.expandFilter = false;
				if(value){
					this.selectedFilters.set(fieldTypeId, ["1"]);
				}
				else {
					this.selectedFilters.delete(fieldTypeId);
				}
				this.$emit('filterChanged', this.selectedFilters);
				this.expandFilter = true;
			},
			kinderbetreuungChanged(fieldTypeId,value){
				this.expandFilter = false;
				if(value){
					this.selectedFilters.set(fieldTypeId, ["1"]);
				}
				else {
					this.selectedFilters.delete(fieldTypeId);
				}
				this.$emit('filterChanged', this.selectedFilters);
				this.expandFilter = true;
			},
			filteringFinished(map){
				/*get's invoked when the user closes the more-filters-modal with the show results-btn this also means, that we'll have to start searching now*/
				this.expandFilter = false;
				this.selectedFilters = map;
				this.$emit('filterChanged', this.selectedFilters);
				this.expandFilter = true;
			},
			resetAllFilters(){
				this.tempShowHide = false;
				this.dateTimeSelection = {
					selectedEndDate: null,
					selectedStartDate: null,
					selectedStartTime: null,
					selectedEndTime: null,
				};
				this.$emit('resetAllFilters', this.selectedFilters);
				this.$nextTick(() => {
					this.tempShowHide = true;
				});
			},
		}
	};
</script>
<style lang="scss" scoped>
	@import '@/scss/_variables.scss';

	.filter-header {
		display: flex;
		margin-top: 30px;
		margin-bottom: 15px;

		.reset-btn {
			margin-left: auto;
		}
	}

	.time-picker-header {
		margin-top: 30px;
		margin-bottom: 15px;
	}

	body[dir="rtl"] { 
		.reset-btn{
			margin-right: auto !important;
			margin-left: 0;
		}
	}
</style>
